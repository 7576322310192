<script>
import Vue from 'vue'
import Switcher from "@/components/switcher";
import { v4 as uuidv4 } from 'uuid'
import duppla from '@/duppla'
import axios from 'axios'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { StripeCheckout } from '@vue-stripe/vue-stripe'
//import AudioRecorder from 'vue-audio-recorder'
//import VueDictaphone from "vue-dictaphone";
import DictaphoneLocal from "@/components/dictaphone-local";
//import VueRecord from '@codekraft-studio/vue-record'
import moment from "moment";
//import Spinner from 'vue-spinkit'
import VueMonthlyPicker from 'vue-monthly-picker'
import WhatsappWidget from "@/components/whatsapp-widget";

import {
  HomeIcon,
  UserIcon,
  UserCheckIcon,
  MailIcon,
  KeyIcon,
  PhoneForwardedIcon,
  PhoneIcon,
  FlagIcon,
  FolderIcon,
  PaperclipIcon,
  XCircleIcon,
  CalendarIcon,
  HashIcon,
  PlusIcon,
  XIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  MicIcon,
  MicOffIcon, StopCircleIcon
} from "vue-feather-icons";

//Vue.use(AudioRecorder)
Vue.prototype.$http = axios
//Vue.use(VueDictaphone);

Vue.use(DictaphoneLocal);
//Vue.use(VueRecord)
//Vue.component('Spinner', Spinner)
/**
 * Auth-signup-three component
 */
export default {
  components: {
    'dictaphone-local': DictaphoneLocal,
    Switcher,
    vueDropzone: vue2Dropzone,
    StripeCheckout,
    VueMonthlyPicker,
    HomeIcon,
    UserIcon,
    UserCheckIcon,
    MailIcon,
    KeyIcon,
    PhoneForwardedIcon,
    PhoneIcon,
    FlagIcon,
    FolderIcon,
    PaperclipIcon,
    XCircleIcon,
    CalendarIcon,
    HashIcon,
    PlusIcon,
    XIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    MicIcon,
    MicOffIcon, StopCircleIcon,
    WhatsappWidget
  },
  data() {
    return {
      recMode: 'press',
      uuid: this.$store.state.auth.tokenPayload.uuid,
      //wTotalSteps: 6,
      wCurrentStep: 0,
      //wPaymentStep: 4,
      user: { fields:{contact_email: ''} },
      form: {},
      countries: [],
      attachments: {pa: [], others:[]},

      stripePublishableKey: process.env.VUE_APP_STK,
      stripeLoading: false,
      stripeSessionId: null,

      fileNameToDelete: '',
      folderToDelete: '',
      deletingObject: false,

      loadingMedicalRecord: false,
      paying: false,
      savingForm: false,
      savingOk: false,

      dropzoneOptions: {
        //url: process.env.VUE_APP_AWSU + 's3/objects',
        url: 'url', // vue-dropzone will set url dynamically
        thumbnailWidth: 150,
        thumbnailHeight: 150,
        method: 'POST',
        //headers: { "Authorization": "Bearer " + this.$store.state.auth.accessToken },
        parallelUploads: 1,
        uploadMultiple: false,
        dictDefaultMessage: 'Para cargar archivos arrastre desde su carpeta, o bien, haga click aquí para seleccionar, puede agregar múltiples archivos a la vez'
      },
      paSigningUrl: {
        signingURL: (f) => {return process.env.VUE_APP_AWSU + 's3/signedPost?folder=pa&filename=' + f.name }
      },
      othersSigningUrl: {
        signingURL: (f) => {return process.env.VUE_APP_AWSU + 's3/signedPost?folder=others&filename=' + f.name }
      },
      awss3: {
          headers: { "Authorization": "Bearer " + this.$store.state.auth.accessToken },
          params: {},
          sendFileToServer: false 
        },
      /*audioRecorderOptions: {
        url: process.env.VUE_APP_AWSU + 's3/objects',
        headers: { "Authorization": "Bearer " + this.$store.state.auth.accessToken },
        attempts: 3,
        time: 2
      },*/
      notesAudio: null,
      notesAudioError: false,
      // isRecording: false,
      scales: {
        a: [
          {value: '100', text:'No presenta síntomas'},
          {value: '90', text:'Síntomas leves que no afectan su vida diaria'},
          {value: '80', text:'Síntomas moderados (lleva su vida diaria a pesar de síntomas, pero requiere mayor esfuerzo)'}
        ],
        b: [
          {value: '70', text:'No requiere asistencia (pediátrico: presenta restricciones y su tiempo de juego se ha reducido)'},
          {value: '60', text:'Requiere ocasionalmente asistencia, pero el paciente puede llevar sus propias actividades (pediátrico: su tiempo de juego se ve limitado y requiere supervisión/asistencia)'},
          {value: '50', text:'Requiere de considerable asistencia (pediátrico: la asistencia para juego activo es siempre necesaria)'}
        ],
        c: [
          {value: '40', text:'Más del 50% del tiempo'},
          {value: '30', text:'Casi todo el tiempo en cama, frecuentemente dormido'},
          {value: '20', text:'Todo el tiempo postrado en cama y requiriendo de ayuda de cuidadores profesionales y/o familiares (pediátrico: no juega, no se levanta)'},
          {value: '10', text:'Completamente en cama y con mínima conciencia o en estado comatoso'}
        ]
      },
      monthLabels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec']
    }
  },
  computed: {
    wSteps () {
      let steps = [
        {
          form: "init",
          submitForm: true
        },{
          form: "pa",
          submitForm: true
        },{
          form: "paadi",
          submitForm: true
        },{
          form: "appq",
          submitForm: true
        },{
          form: "agine",
          submitForm: true
        },{
          form: "apnp",
          submitForm: true
        },{
          form: "checkout",
          submitForm: true
        },{
          form: "checkout_confirmation",
          submitForm: true,
          lastStep: true
        },{
          form: "end",
          submitForm: true
        }
      ]
      if (this.user.fields) {
        if (this.user.fields.payment) {
          steps.splice(6, 1) // checkout
        } else {
          steps.splice(7, 1) // checkout_confirmation
        }

        if (this.form.patient_sex === 'male') {
          steps.splice(4, 1) // agine
        }
      }
      return steps
    },
    wTotalSteps () {
      return this.wSteps.length - 1
    },
    wProgress () {
      return (this.wCurrentStep / this.wTotalSteps) * 100
    },
    wFirstStep () {
      if (this.wCurrentStep === 0) {
        return true
      } else {
        return false
      }
    },
    wLastStep () {
      if (this.wCurrentStep === this.wTotalSteps) {
        return true
      } else {
        return false
      }
    },
    linkedLists () {
      let list = [
        "symptoms",
        "allergies",
        "surgeries",
        "medicines",
        "direct_relatives",
        "diseases"
      ]
      return list
    },
    fields () {
      let fields = ''
      fields += '?fields%5B%5D=activation_date'
      fields += '&fields%5B%5D=contact_email'
      fields += '&fields%5B%5D=contact_firstname'
      fields += '&fields%5B%5D=contact_lastname'
      fields += '&fields%5B%5D=contact_phone_country'
      fields += '&fields%5B%5D=contact_phone'

      fields += '&fields%5B%5D=patient_city'
      fields += '&fields%5B%5D=contact_source'
      fields += '&fields%5B%5D=patient_social_security'

      fields += '&fields%5B%5D=user_is_patient'
      fields += '&fields%5B%5D=patient_firstname'
      fields += '&fields%5B%5D=patient_lastname'
      fields += '&fields%5B%5D=patient_country'
      fields += '&fields%5B%5D=patient_birth'
      fields += '&fields%5B%5D=patient_sex'
      fields += '&fields%5B%5D=weight'
      fields += '&fields%5B%5D=height'
      fields += '&fields%5B%5D=service_reason'
      fields += '&fields%5B%5D=current_diagnosis'
      fields += '&fields%5B%5D=diagnosis_date'
      fields += '&fields%5B%5D=tumor'
      fields += '&fields%5B%5D=tumor_palpable'
      fields += '&fields%5B%5D=tumor_visible'
      fields += '&fields%5B%5D=tumor_painful'
      fields += '&fields%5B%5D=tumor_moving'
      fields += '&fields%5B%5D=tumor_type'
      fields += '&fields%5B%5D=scale_q1'
      fields += '&fields%5B%5D=scale_q2'
      fields += '&fields%5B%5D=scale_kps'
      fields += '&fields%5B%5D=symptoms'
      fields += '&fields%5B%5D=treatment'
      fields += '&fields%5B%5D=medicine'
      fields += '&fields%5B%5D=medicines'
      fields += '&fields%5B%5D=notes'
      fields += '&fields%5B%5D=notes_type'
      
      fields += '&fields%5B%5D=pending_studies'
      fields += '&fields%5B%5D=pending_studies_list'

      // step 2
      fields += '&fields%5B%5D=allergy'
      fields += '&fields%5B%5D=allergies'
      fields += '&fields%5B%5D=transfusion'
      fields += '&fields%5B%5D=transfusion_date'
      fields += '&fields%5B%5D=transfusion_reason'
      fields += '&fields%5B%5D=surgery'
      fields += '&fields%5B%5D=surgeries'
      fields += '&fields%5B%5D=body'
      fields += '&fields%5B%5D=disease'
      fields += '&fields%5B%5D=diseases'

      //agine
      fields += '&fields%5B%5D=f_menstruation_age'
      fields += '&fields%5B%5D=ivsa_age'
      fields += '&fields%5B%5D=l_menstruation_age'
      fields += '&fields%5B%5D=pregnant'
      fields += '&fields%5B%5D=previous_pregnancies'
      fields += '&fields%5B%5D=previous_pregnancies_qty'
      fields += '&fields%5B%5D=birth_qty'
      fields += '&fields%5B%5D=c_section_qty'
      fields += '&fields%5B%5D=abortion_qty'
      fields += '&fields%5B%5D=lactation_months'

      fields += '&fields%5B%5D=payment'
      fields += '&fields%5B%5D=payment_date'
      fields += '&fields%5B%5D=card_brand'
      fields += '&fields%5B%5D=card_last4'
      fields += '&fields%5B%5D=receipt_url'
      fields += '&fields%5B%5D=stripe_session_id'
      fields += '&fields%5B%5D=stripe_session_status'
      fields += '&fields%5B%5D=stripe_payment_method_type'
      fields += '&fields%5B%5D=stripe_hosted_voucher_url'

      // step 3
      fields += '&fields%5B%5D=smoke'
      fields += '&fields%5B%5D=smoke_years'
      fields += '&fields%5B%5D=smoke_qty_day'
      fields += '&fields%5B%5D=alcohol'
      fields += '&fields%5B%5D=alcohol_frequency'
      fields += '&fields%5B%5D=alcohol_amount'
      fields += '&fields%5B%5D=alcohol_years'
      fields += '&fields%5B%5D=drugs'
      fields += '&fields%5B%5D=drugs_description'
      fields += '&fields%5B%5D=drugs_frequency'
      fields += '&fields%5B%5D=drugs_years'
      fields += '&fields%5B%5D=sleep'
      // fields += '&fields%5B%5D=sleep_description'
      // fields += '&fields%5B%5D=sleep_hours'
      fields += '&fields%5B%5D=exercise'
      // fields += '&fields%5B%5D=exercise_frequency'
      // fields += '&fields%5B%5D=excercise_minutes'
      fields += '&fields%5B%5D=eating_habit'
      fields += '&fields%5B%5D=direct_relative'
      fields += '&fields%5B%5D=direct_relatives'
      // fields += '&fields%5B%5D=indirect_relative'
      // fields += '&fields%5B%5D=indirect_relative_who'
      // fields += '&fields%5B%5D=indirect_relative_disease'

      fields += '&fields%5B%5D=wizard_step'

      fields += '&fields%5B%5D=form_completed_date'
      return fields
    },
    scale_kps_label () {
      if (this.form.scale_q1 === 'yes'){
        return '¿El paciente presenta síntomas?'
      } else if (this.form.scale_q1 === 'no' && this.form.scale_q2 === 'no'){
        return '¿El paciente requiere de asistencia para actividades diarias (adultos: aseo, alimentación, vestirse. Niños: en el juego diario)?'
      } else if (this.form.scale_q1 === 'no' && this.form.scale_q2 === 'yes'){
        return '¿A qué grado está confinado a estar postrado en cama el paciente?'
      } else {
        return ''
      }
    },
    scalesComputed () {
      if (this.form.scale_q1 === 'yes'){
        return this.scales.a
      } else if (this.form.scale_q1 === 'no' && this.form.scale_q2 === 'no'){
        return this.scales.b
      } else if (this.form.scale_q1 === 'no' && this.form.scale_q2 === 'yes'){
        return this.scales.c
      } else {
        return []
      }
    },
    currYear () {
      return moment().year()
    },
    currDate () {
      return moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    wStepIndex(currentStep) {
      for(var i=0; i<this.wSteps.length; i++) {
        if(this.wSteps[i].form === currentStep) {
          return i
        }
      }
      return 0
    },
    wStepName(index) {
      return this.wSteps[index].form
    },
    nextStep() {
      if(this.wCurrentStep < this.wTotalSteps){
        var stepsForward = 1
        /*if (this.user.fields.payment && (this.wCurrentStep + 1) == this.wPaymentStep){
          stepsForward = 2
        }*/
        var currentForm = this.$refs['form_' + this.wStepName(this.wCurrentStep)]
        if(currentForm) {
          if(currentForm.checkValidity()){
            var originalStep = this.wCurrentStep
            this.wCurrentStep += stepsForward
            this.goto('wizard')
            if (this.wSteps[originalStep].lastStep) {
              this.form.form_completed_date = new Date()
              this.form.status = '1'
            }
            this.submit().then(() => {
              delete this.form.form_completed_date
              delete this.form.status
              this.getLinkedLists()
            })
          } else {
            currentForm.reportValidity()
          }
        } else {
          // if no form then nothing to validate, just submit to save step
          this.wCurrentStep += stepsForward
          this.goto('wizard')
          this.submit()
        }
      }
    },
    previousStep() {
      if(this.wCurrentStep > 0){
        var stepsBack = 1
        /*if (this.user.fields.payment && (this.wCurrentStep - 1) == this.wPaymentStep){
          stepsBack = 2
        }*/
        this.wCurrentStep -= stepsBack
        this.goto('wizard')
      }
      return
    },
    getMedicalRecord () {
      return new Promise((resolve, reject) => {
        if(!this.loadingMedicalRecord){
          this.loadingMedicalRecord = true
          duppla({ url: 'my-medical-record' + this.fields, method: 'GET', data: null })
            .then(response => {
              if(!response.data){
                throw new Error("Expecting one record")
              }
              this.user = response.data
              this.form = {}

              //Object.assign(this.form, this.user.fields)
              this.form = JSON.parse(JSON.stringify(this.user.fields));

              let activationDate = this.user.fields.activation_date

              //if user has completed form
              if (this.form.wizard_step === 'end') {
                this.$router.push('/private/dashboard')
              }
              this.wCurrentStep = this.wStepIndex(this.form.wizard_step)
              if (isNaN(this.wCurrentStep) || !this.wCurrentStep) {
                this.wCurrentStep = 0
              }
              /*
              if (!activationDate) {
                this.updateActivationDate(this.user.id)
              }
              */
              //Removing fields that can't be changed by this form for security
              delete this.form.contact_email
              delete this.form.contact_firstname
              delete this.form.activation_date
              delete this.form.form_completed_date

              delete this.form.payment
              delete this.form.payment_date
              delete this.form.card_brand
              delete this.form.card_last4
              delete this.form.receipt_url

              delete this.form.stripe_session_id
              delete this.form.stripe_session_status
              delete this.form.stripe_payment_method_type
              delete this.form.stripe_hosted_voucher_url


              //Airtable linked records
              if (this.form.contact_phone_country){
                this.form.contact_phone_country = this.form.contact_phone_country[0]
              }
              if (this.form.patient_country){
                this.form.patient_country = this.form.patient_country[0]
              }

              this.getAttachments('pa')
              this.getAttachments('others')
              this.getAttachments('audio').then(response => {
                // console.log('response audios: ' + JSON.stringify(response, null, 2))
                for (var i=0; i<response.length; i++) {
                  if (response[i].Filename === 'notes.mp3') {
                    this.notesAudio = response[i].Url
                    break;
                  }
                }
              })
              resolve(this.form.wizard_step)
            })
            .catch(error => {
              reject(error)
            })
            .finally(() => {
              this.loadingMedicalRecord = false
            })
        }
      })
    },
    invokePayment () {
      if(!this.paying){
        this.paying = true
        let parameters = {
          price: process.env.VUE_APP_STK_PRICE,
          success_url: window.location.href.split('/').slice(0, 3).join('/') + "/private/medical-record-wizard",
          cancel_url: window.location.href.split('/').slice(0, 3).join('/') + "/private/medical-record-wizard",
          client_reference_id: this.uuid,
          customer_email: this.user.fields.contact_email
        }

        duppla({ url: 'stripe/createSession', method: 'POST', data: parameters })
          .then(response => {
            // console.log(response.data)
            this.stripeSessionId = response.data.id
            this.$refs.checkoutRef.redirectToCheckout()
          })
          .finally(() => {
            this.paying = false
          })
      }
    },
    submitAndleave () {
      this.submit().then(() => {
        this.$router.push('/private/dashboard')
      })
    },
    submit () {
      return new Promise((resolve, reject) => {
        if (!this.savingForm){
          this.savingForm = true

          if (this.form.user_is_patient){
            this.form.patient_firstname = this.user.fields.contact_firstname
            this.form.patient_lastname = this.user.fields.contact_lastname
          }
          if(this.form.scale_q1 == 'yes'){
            this.form.scale_q2 = null
          }
          
          //Object.assign(cleanForm, this.form)
          let cleanForm = JSON.parse(JSON.stringify(this.form));

          //Airtable linked records
          if (cleanForm.contact_phone_country) {
            cleanForm.contact_phone_country = [cleanForm.contact_phone_country]
          }
          if (cleanForm.patient_country) {
            cleanForm.patient_country = [cleanForm.patient_country]
          }

          //numeric values
          /*
          if (cleanForm.weight) {
            cleanForm.weight = Number(cleanForm.weight)
          }
          if (cleanForm.height) {
            cleanForm.height = Number(cleanForm.height)
          }
          */

          // cleanForm.wizard_step = String(this.wCurrentStep)
          cleanForm.wizard_step = this.wStepName(this.wCurrentStep)


          let record = {id: this.user.id, fields: cleanForm}
          // let obj = {records: []}
          // obj.records.push(record)

          // console.log('submit form')
          duppla({ url: 'my-medical-record', method: 'PATCH', data: record })
            .then(response => {
              resolve()
            })
            .catch(error => {
              reject(error)
            })
            .finally(() => {
              this.savingForm = false
            })
        } else {
          reject('Another submit operation running')
        }
      })
    },
    getCountries () {
      duppla({ url: 'countries', method: 'GET' })
        .then(response => {
          this.countries = response.data
        })
    },
    getAttachments (folder) {
      return new Promise((resolve, reject) => {
        duppla({ url: 's3/objects?folder=' + folder, method: 'GET', data: null })
          .then(response => {
            // console.log('response getAttachments: ' + JSON.stringify(response, null, 2))
            this.attachments[folder] = response.data.Contents
            resolve(this.attachments[folder])
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {})
      })
    },
    deleteObject (folder, fileName) {
      if (!this.deletingObject){
        this.deletingObject = true
        duppla({ url: 's3/objects', method: 'DELETE', data: {fileName: fileName, folder: folder} })
          .then(response => {
            this.getAttachments(folder).then((result) => {}).finally(() => {
              this.$refs['DeleteObjectModal'].hide()
              this.fileNameToDelete = ''
              this.folderToDelete = ''
              setTimeout(() => { this.deletingObject=false }, 100)
            })
          })
          .finally(() => {})
      }
    },
    async paBeforeSendFile (file, xhr, formData) {
      /*
      formData.append('key', this.uuid + '/pa/' + file.name);
      console.log(file)
      */
     /*
      let preSignedUrl = await duppla({ url: 's3/putobjecturl?folder=pa&filename='+file.name, method: 'GET'})
      console.log("preSignedUrl: " + preSignedUrl.data)
      this.$refs.paDropzone.setOption('url', preSignedUrl.data);
      this.$refs.paDropzone.setOption('Content-Length', file.size)
      console.log("Termina el paBeforeSendFile")
      */
    },
    paAfterSuccess (file) {
      //console.log('paAfterSuccess file: ' + JSON.stringify(file, null, 2))
      this.getAttachments('pa').then((result) => {
        this.$refs.paDropzone.removeFile(file)
      })
    },
    othersBeforeSendFile (file, xhr, formData) {
      formData.append('key', this.uuid + '/others/' + file.name);
    },
    othersAfterSuccess (file) {
      // console.log('file: ' + JSON.stringify(file, null, 2))
      this.getAttachments('others').then((result) => {
        this.$refs.othersDropzone.removeFile(file)
      })
    },
    recordingPressed () {
      if (this.notesAudio === null) { 
        this.startRecordingAudio()
      } else {
        this.$refs['ReplaceAudioModal'].show()
      }
    },
    startRecordingAudio () {
      this.notesAudioError = false
      this.$refs['ReplaceAudioModal'].hide()
      this.$refs.notesAudioDictaphone.startRecording()
    },
    handleRecording({ blob, src }) {
      this.notesAudio = src;
      // console.log("blob: " + blob)
      // console.log("src: " + src)
      blob.name = 'notes.mp3'
      const data = new FormData()
      data.append('file', blob, blob.name);
      data.append('key', this.uuid + '/audio/' + blob.name)
      duppla({ url: 's3/objects', method: 'PUT', data: data, headers: { "Content-Type": "multipart/form-data" } })
        .then(response => {
          // console.log("Audio guardado!")
        })
        .catch(error => {
          this.notesAudioError = true
        })
      //this.$refs.audioDropzone.addFile(blob)
    },
    /*
    onStream() {
      this.isRecording = true
    },
    handleRecording2 (blob) {
      this.isRecording = false
      this.notesAudio = window.URL.createObjectURL(blob)
      blob.name = 'notes.mp3'
      const data = new FormData()
      data.append('file', blob, blob.name);
      data.append('key', this.uuid + '/audio/' + blob.name)
      duppla({ url: 's3/objects', method: 'PUT', data: data, headers: { "Content-Type": "multipart/form-data" } })
        .then(response => {
          console.log("Audio guardado!")
        })
        .catch(error => {
          console.error('Error: ' + error)
        })
    },
    */
    userIsPatientToggle () {
      if(this.form.user_is_patient) {
        this.form.patient_firstname = this.user.fields.contact_firstname
        this.form.patient_lastname = this.user.fields.contact_lastname
      } else {
        this.form.patient_firstname = ''
        this.form.patient_lastname = ''
      }
    },
    scaleKpsChange () {
      // console.log('changing kps: ' + this.form.scale_kps)
      if (this.form.scale_kps === '100') {
        if (this.form.symptoms) {
          this.form.symptoms.splice(0)
        }
      } else {
        // console.log('this is the current length fo symtpoms:' + this.form.symptoms.length)
        if (this.form.symptoms.length === 0) { 
          this.addFormListItem("symptoms")
        }
      }
    },
    allergyChange () {
      // console.log('changing allergy: ' + this.form.allergy)
      if (this.form.allergy === 'no') {
        if (this.form.allergies) {
          this.form.allergies.splice(0)
        }
      } else {
        if (this.form.allergies.length === 0) { 
          this.addFormListItem("allergies")
        }
      }
    },
    surgeryChange () {
      // console.log('changing surgery: ' + this.form.surgery)
      if (this.form.surgery === 'no') {
        if (this.form.surgeries) {
          this.form.surgeries.splice(0)
        }
      } else {
        if (this.form.surgeries.length === 0) { 
          this.addFormListItem("surgeries")
        }
      }
    },
    medicineChange () {
      // console.log('changing surgery: ' + this.form.medicine)
      if (this.form.medicine === 'no') {
        if (this.form.medicines) {
          this.form.medicines.splice(0)
        }
      } else {
        if (this.form.medicines.length === 0) { 
          this.addFormListItem("medicines")
        }
      }
    },
    directRelativeChange () {
      if (this.form.direct_relatives === 'no') {
        if (this.form.direct_relatives) {
          this.form.direct_relatives.splice(0)
        }
      } else {
        if (this.form.direct_relatives.length === 0) { 
          this.addFormListItem("direct_relatives")
        }
      }
    },
    diseaseChange () {
      if (this.form.disease === 'no') {
        if (this.form.diseases) {
          this.form.diseases.splice(0)
        }
      } else {
        if (this.form.diseases.length === 0) { 
          this.addFormListItem("diseases")
        }
      }
    },
    addFormListItem (list) {
      let item = {
        fields: {
          uuid: uuidv4(),
          medical_record_uuid: this.uuid,
          medical_record: [this.user.id]
        }
      }

      // select form inputs
      if (list === "allergies") {
        item.fields.severity = null
      }
      if (list === "surgeries") {
        item.fields.general_anesthesia = null
      }
      if (list === "direct_relatives") {
        item.fields.relative = null
        item.fields.alive = null
        item.fields.diagnosis_age = null
      }
      if (list === "diseases") {
        item.fields.name = null
        item.fields.resolved = null
      }
      this.form[list].push(item)
    },
    removeFormListItem (list, index) {
      if(this.form[list][index].id) {
        if (!this.form[list + "_delete"]) {
          this.form[list + "_delete"] = []
        }
        this.form[list + "_delete"].push(this.form[list][index].id)
        this.form[list].splice(index, 1)
      } else {
        this.form[list].splice(index, 1)
      }
    },
    getLinkedLists () {
      let fields = '?'

      for (var i= 0; i<this.linkedLists.length; i++) {
        fields += '&fields%5B%5D=' + this.linkedLists[i]
      }
      // console.log('getLinkedLists: ' + fields)
      duppla({ url: 'my-medical-record' + fields, method: 'GET', data: null })
        .then(response => {
          // console.log(JSON.stringify(response.data.fields, null, 2))
          for (var i= 0; i<this.linkedLists.length; i++) {
            this.form[this.linkedLists[i]] = response.data.fields[this.linkedLists[i]]
            delete this.form[this.linkedLists[i] + "_delete"]
          }
        })
    },
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    }
  },
  created () {
    this.getCountries()
    this.getMedicalRecord().then((step) => {
      if (this.user.fields.payment && step === 'checkout') {
        this.wCurrentStep = this.wStepIndex('checkout_confirmation')
      }
    }).finally(() => {})
  },
  mounted() {
  }
};
</script>
<style type="text/css">
  .dropzone .dz-preview .dz-image {
    width: 120px;
    height: 120px;
  }
  .ar *,
  .ar *:before,
  .ar *:after {
      -webkit-box-sizing: content-box !important;
      -moz-box-sizing: content-box !important;
      box-sizing: content-box !important;
  }
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
      /*display: none;
      -webkit-appearance: none;
      opacity: 0;*/
  }
</style>

<template>
  <div>
    <div class="back-to-home rounded d-none d-sm-block">
      <b-btn class="btn btn-icon btn-primary" @click.prevent="submitAndleave" :disabled="savingForm">
        <home-icon class="icons"></home-icon>
      </b-btn>
    </div>
    <section class="bg-half bg-light w-100" style="padding-top: 50px; padding-bottom: 50px;">
      <div class="container">
          <div class="row">
              <div class="col-lg-12 ">
                <h4 class="title"> Formulario Clínico </h4>
                <p>Por favor siga las instrucciones para completar el expediente médico.</p>
                <b-btn class="btn btn-primary" @click.prevent="submitAndleave" :disabled="savingForm">
                  Guardar y regresar más tarde
                </b-btn>
              </div>
              <!--end col-->
          </div>
          <!--end row-->
      </div>
      <!--end container-->
    </section>
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!-- Hero Start -->
    <section class="mt-5">
      <div class="container" ref="wizard">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-12">
            <div class="progress-box">
              <h5 class="title text-muted">Progreso <span class="d-none d-md-inline">de su expediente</span><span class="float-right">Paso {{wCurrentStep + 1}} de {{wTotalSteps + 1}}</span></h5>
              <div class="progress">
                <div class="progress-bar position-relative bg-primary" v-bind:style="{width: wProgress + '%'}">
                  <div class="progress-value d-block text-muted h6">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-10 col-12">
            <div class="card login_page shadow rounded shadow-lg p-4 mb-5">
              <div class="card-body">
                  <div class="row justify-content-center" v-if="loadingMedicalRecord">
                    <!--Spinner name="heart" color="#2F55D4"/-->
                    <b-spinner style="width: 3rem; height: 3rem;" variant="primary" label="Cargando..."></b-spinner>
                  </div>
                
                  <form class="login-form" ref="form_init" v-if="wStepName(wCurrentStep) === 'init' && !loadingMedicalRecord">
                    <h4 class="card-title">Información de Contacto</h4>
                    <hr/>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Lada de Contacto <span class="text-danger">*</span></label>
                          <div class="position-relative">
                            <phone-forwarded-icon class="fea icon-sm icons"></phone-forwarded-icon>
                            <b-select class="form-control pl-5" v-model="form.contact_phone_country" :options="countries" required text-field="textLada" value-field="value">
                              <template #first>
                                <b-select-option :value="null" disabled>-- Lada --</b-select-option>
                              </template>
                            </b-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Teléfono de Contacto <span class="text-danger">*</span></label>
                          <div class="position-relative">
                            <phone-icon class="fea icon-sm icons"></phone-icon>
                            <input type="tel" class="form-control pl-5" placeholder="Teléfono de 10 dígitos" v-model="form.contact_phone" required maxlength="10" minlength="10" pattern="[0-9]{10}"/>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>¿Cómo te enteraste de nosotros? <span class="text-danger">*</span></label>
                          <b-select class="form-control" v-model="form.contact_source" required>
                            <template #first>
                              <b-select-option :value="null" disabled>-- Cómo te enteraste --</b-select-option>
                            </template>

                            <!-- These options will appear after the ones from 'options' prop -->
                            <b-select-option value="Familia y/o amigos">Familia y/o amigos</b-select-option>
                            <b-select-option value="Médico tratante">Médico tratante</b-select-option>
                            <b-select-option value="Asociación Mexicana de Lucha Contra el Cáncer">Asociación Mexicana de Lucha Contra el Cáncer</b-select-option>
                            <b-select-option value="Otras asociaciones o comunidades de salud">Otras asociaciones o comunidades de salud</b-select-option>
                            <b-select-option value="Redes sociales">Redes sociales</b-select-option>
                            <b-select-option value="Búsqueda en internet">Búsqueda en internet</b-select-option>
                            <b-select-option value="Otro">Otro</b-select-option>
                          </b-select>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="userIsPatient" v-model="form.user_is_patient" @change.prevent="userIsPatientToggle"/>
                            <label class="custom-control-label" for="userIsPatient">
                              ¿{{user.fields.contact_firstname}}, eres tú el paciente?
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br/>
                    <h4 class="card-title">Información del Paciente</h4>
                    <hr/>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Nombre del Paciente<span class="text-danger">*</span></label>
                          <div class="position-relative">
                            <user-icon class="fea icon-sm icons"></user-icon>
                            <input
                              type="text"
                              class="form-control pl-5"
                              placeholder="Nombre del Paciente"
                              name="s"
                              required
                              v-model="form.patient_firstname"
                              :disabled="form.user_is_patient"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Apellido(s) del Paciente <span class="text-danger">*</span></label>
                          <div class="position-relative">
                            <user-check-icon
                              class="fea icon-sm icons"
                            ></user-check-icon>
                            <input
                              type="text"
                              class="form-control pl-5"
                              placeholder="Apellido(s) del Paciente"
                              name="s"
                              required
                              v-model="form.patient_lastname"
                              :disabled="form.user_is_patient"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Fecha de Nacimiento<span class="text-danger">*</span></label>
                          <div class="position-relative">
                            <!--calendar-icon class="fea icon-sm icons"></calendar-icon-->
                            <input type="date" class="form-control" placeholder="dd/mm/yyyy" v-model="form.patient_birth" required :max="currDate"/>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="form-group">
                          <label>Sexo de nacimiento <span class="text-danger">*</span></label>
                          <div class="ml-md-3 position-relative custom-control custom-radio">
                            <input type="radio" id="female" name="patientSex" class="custom-control-input" value="female" v-model="form.patient_sex" required/>
                            <label class="custom-control-label" for="female">Femenino</label>
                          </div>
                          <div class="ml-md-3 position-relative custom-control custom-radio">
                            <input type="radio" id="male" name="patientSex" class="custom-control-input" value="male" v-model="form.patient_sex" required/>
                            <label class="custom-control-label" for="male">Masculino</label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Peso (en Kilogramos sin decimales)<span class="text-danger">*</span></label>
                          <div class="position-relative">
                            <hash-icon class="fea icon-sm icons"></hash-icon>
                            <input type="number" class="form-control pl-5" placeholder="ej. 65" v-model="form.weight" required min="1" max="500"/>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label>Altura (en Centimetros sin decimales)<span class="text-danger">*</span></label>
                          <div class="position-relative">
                            <hash-icon class="fea icon-sm icons"></hash-icon>
                            <input type="number" class="form-control pl-5" placeholder="ej. 175" v-model="form.height" required min="10" max="250"/>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>País de Residencia <span class="text-danger">*</span></label>
                          <div class="position-relative">
                            <flag-icon class="fea icon-sm icons"></flag-icon>
                            <b-select class="form-control pl-5" v-model="form.patient_country" :options="countries" required text-field="textCountry" value-field="value">
                              <template #first>
                                <b-select-option :value="null" disabled>-- País --</b-select-option>
                              </template>
                            </b-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label>Ciudad de Residencia <span class="text-danger">*</span></label>
                          <input type="text" class="form-control" placeholder="Ciudad de Residencia" v-model="form.patient_city" required/>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>¿Con qué tipo de seguridad social cuentas? <span class="text-danger">*</span></label>
                          <b-select class="form-control" v-model="form.patient_social_security" required>
                            <template #first>
                              <b-select-option :value="null" disabled>-- Tipo de Seguridad Social --</b-select-option>
                            </template>

                            <!-- These options will appear after the ones from 'options' prop -->
                            <b-select-option value="Ninguna">Ninguna</b-select-option>
                            <b-select-option value="Seguridad Social del Estado (sistema de salud público)">Seguridad Social del Estado (sistema de salud público)</b-select-option>
                            <b-select-option value="Seguro de Gastos Médicos Mayores Privado">Seguro de Gastos Médicos Mayores Privado</b-select-option>
                            <b-select-option value="Otro">Otro</b-select-option>
                          </b-select>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label>Motivo por el que requieres este servicio <span class="text-danger">*</span></label>
                          <div class="position-relative">
                            <b-textarea v-model="form.service_reason" :rows="10" :max-rows="15" required style="line-height: normal;"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>


                  <form class="login-form" ref="form_pa" v-if="wStepName(wCurrentStep) === 'pa'">
                    <h4 class="card-title">Patología Actual</h4>
                    <hr/>
                    <div class="row">
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="6" content-cols-sm="6" content-cols-lg="6" label="¿Cuál es el padecimiento (enfermedad) para el cuál requiere una segunda opinión?" >
                          <input type="text" class="form-control" placeholder="ej. Enfermedad" required v-model="form.current_diagnosis"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="6" content-cols-sm="6" content-cols-lg="6" label="¿Cuándo fue diagnosticado?" >
                          <div class="position-relative">
                            <!--calendar-icon class="fea icon-sm icons"></calendar-icon-->
                            <input type="date" class="form-control" placeholder="dd/mm/yyyy" v-model="form.diagnosis_date" required :max="currDate"/>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Padece de algún tumor?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="tumorYes" name="tumor" class="custom-control-input" value="yes" v-model="form.tumor" required/>
                            <label class="custom-control-label" for="tumorYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="tumorNo" name="tumor" class="custom-control-input" value="no" v-model="form.tumor" required/>
                            <label class="custom-control-label" for="tumorNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.tumor === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Su tumor le causa dolor?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="tumorPainfulYes" name="tumor_painful" class="custom-control-input" value="yes" v-model="form.tumor_painful" required/>
                            <label class="custom-control-label" for="tumorPainfulYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="tumorPainfulNo" name="tumor_painful" class="custom-control-input" value="no" v-model="form.tumor_painful" required/>
                            <label class="custom-control-label" for="tumorPainfulNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.tumor === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Su tumor es palpable?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="tumorPalpableYes" name="tumor_palpable" class="custom-control-input" value="yes" v-model="form.tumor_palpable" required/>
                            <label class="custom-control-label" for="tumorPalpableYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="tumorPalpableNo" name="tumor_palpable" class="custom-control-input" value="no" v-model="form.tumor_palpable" required/>
                            <label class="custom-control-label" for="tumorPalpableNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.tumor_palpable === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Su tumor es visible?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="tumorVisibleYes" name="tumor_visible" class="custom-control-input" value="yes" v-model="form.tumor_visible" required/>
                            <label class="custom-control-label" for="tumorVisibleYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="tumorVisibleNo" name="tumor_visible" class="custom-control-input" value="no" v-model="form.tumor_visible" required/>
                            <label class="custom-control-label" for="tumorVisibleNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.tumor_palpable === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Su tumor se mueve?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="tumorMovingYes" name="tumor_moving" class="custom-control-input" value="yes" v-model="form.tumor_moving" required/>
                            <label class="custom-control-label" for="tumorMovingYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="tumorMovingNo" name="tumor_moving" class="custom-control-input" value="no" v-model="form.tumor_moving" required/>
                            <label class="custom-control-label" for="tumorMovingNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.tumor_palpable === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Su tumor es blando o duro?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="tumorTypeYes" name="tumor_type" class="custom-control-input" value="hard" v-model="form.tumor_type" required/>
                            <label class="custom-control-label" for="tumorTypeYes">Duro</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="tumorTypeNo" name="tumor_type" class="custom-control-input" value="soft" v-model="form.tumor_type" required/>
                            <label class="custom-control-label" for="tumorTypeNo">Blando</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿El paciente lleva su actividad de manera normal?">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="scaleQ1Yes" name="scale_q1" class="custom-control-input" value="yes" v-model="form.scale_q1" required/>
                            <label class="custom-control-label" for="scaleQ1Yes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="scaleQ1No" name="scale_q1" class="custom-control-input" value="no" v-model="form.scale_q1" required/>
                            <label class="custom-control-label" for="scaleQ1No">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.scale_q1 == 'no'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿El paciente reposa en cama más de la mitad de su día (sin contar la noche)?">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="scaleQ2Yes" name="scale_q2" class="custom-control-input" value="yes" v-model="form.scale_q2" required/>
                            <label class="custom-control-label" for="scaleQ2Yes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="scaleQ2No" name="scale_q2" class="custom-control-input" value="no" v-model="form.scale_q2" required/>
                            <label class="custom-control-label" for="scaleQ2No">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="(form.scale_q1 && form.scale_q2) || form.scale_q1 === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" :label="scale_kps_label">
                          <b-select class="form-control" v-model="form.scale_kps" :options="scalesComputed" required text-field="text" value-field="value" @change="scaleKpsChange">
                            <template #first>
                              <b-select-option :value="null" disabled>-- Seleccione --</b-select-option>
                            </template>
                          </b-select>
                        </b-form-group>
                      </div>
                      <span v-if="form.scale_kps && form.scale_kps != '100'">
                        <div class="col-md-12 form-inline mb-4">
                          Por favor especifique los síntomas y/o malestares que ha presentado el paciente, así como mes y año aproximado en la que apareció por primera ocasión, agregue cuantas líneas necesite:
                          <p class="text-muted"><i>Ej. Vómitos, Náuseas, Mareos, Desmayos, Dolores, Inmovilidad, etc...</i></p>
                        </div>
                        <span v-for="(item, index) in form.symptoms">
                          <div class="col-md-12 form-inline mb-4" v-if="item.action != 'delete'">
                            <label class="sr-only" :for="'sy1_'+index">Síntoma/Malestar</label>
                            <input type="text" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'sy1_'+index" placeholder="Síntoma/Malestar" required v-model="item.fields.description" style="min-width: 200px;" />
                            <label class="sr-only" :for="'sy2_'+index">Fecha</label>
                            <!--input type="date" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'sy2_'+index" placeholder="dd/mm/yyyy" v-model="item.fields.date" required :max="currDate"/-->
                            <vue-monthly-picker v-model="item.fields.date" :monthLabels="monthLabels" placeHolder="-- Mes y Año --" dateFormat="MMM YYYY" selectedBackgroundColor="#2f55d4" required class="mr-2 mb-2 mb-sm-0" :max="currDate"></vue-monthly-picker>
                            <a href="#" class="btn btn-icon btn-pills btn-outline-danger" v-if="form.symptoms.length > 1" @click.prevent="removeFormListItem('symptoms', index)"><x-icon class="fea icon-sm"></x-icon></a>
                          </div>
                        </span>
                        <div class="col-md-12 mb-4">
                          <a href="#" class="btn btn-icon btn-pills btn-primary" @click.prevent="addFormListItem('symptoms')"><plus-icon class="fea icon-sm"></plus-icon></a>
                          <b-btn class="btn btn-primary ml-3" @click.prevent="addFormListItem('symptoms')">
                            Agregar Síntoma
                          </b-btn>
                        </div>
                      </span>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Actualmente toma algún medicamento para este padecimiento concreto?">
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="medicineYes" name="medicine" class="custom-control-input" value="yes" v-model="form.medicine" required @change="medicineChange"/>
                            <label class="custom-control-label" for="medicineYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="medicineNo" name="medicine" class="custom-control-input" value="no" v-model="form.medicine" required @change="medicineChange"/>
                            <label class="custom-control-label" for="medicineNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <span v-if="form.medicine === 'yes'">
                        <div class="col-md-12 form-inline mb-4">
                          Por favor agregue el nombre comercial o genérico del medicamento que toma, su dosis, frecuencia (cada cuanto se lo toma) y duración (hasta cuando la tomará) y cualquier otra indicación de administración de su médico, agregue cuantas sean necesarias:
                        </div>
                        <span v-for="(item, index) in form.medicines">
                          <div class="col-md-12 form-inline mb-4">
                            <label class="sr-only" :for="'me1_'+index"  >Medicamento</label>
                            <input type="text" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'me1_'+index" placeholder="Medicamento" required v-model="item.fields.name"/>
                            <label class="sr-only" :for="'me2_'+index">Dosis</label>
                            <input type="text" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'me2_'+index" placeholder="Dosis" required v-model="item.fields.dose"/>
                            <label class="sr-only" :for="'me3_'+index">Frecuencia</label>
                            <input type="text" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'me3_'+index" placeholder="Frecuencia" required v-model="item.fields.frequency"/>
                          </div>
                          <div class="col-md-12 form-inline mb-4">
                            <label class="sr-only" :for="'me5_'+index">Duración</label>
                            <input type="text" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'me5_'+index" placeholder="Duración" required v-model="item.fields.duration"/>
                            <label class="sr-only" :for="'me4_'+index">Otras Indicaciones</label>
                            <input type="text" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'me3_'+index" placeholder="Otras indicaciones" v-model="item.fields.indications"/>
                            <a href="#" class="btn btn-icon btn-pills btn-outline-danger" v-if="form.medicines.length > 1" @click.prevent="removeFormListItem('medicines', index)"><x-icon class="fea icon-sm"></x-icon></a>
                          </div>
                        </span>
                        <div class="col-md-12 mb-4">
                          <b-btn class="btn btn-primary" @click.prevent="addFormListItem('medicines')">
                            Agregar Medicamento
                          </b-btn>
                        </div>
                      </span>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Qué procedimiento adicional (incluyendo posibles cirugías) le han indicado para su padecimiento?" >
                          <b-textarea v-model="form.treatment" :rows="10" :max-rows="15" style="line-height: normal;"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="Por favor cargue TODOS los archivos relacionados a su padecimiento:" description="">
                          <vue-dropzone 
                            ref="paDropzone" 
                            id="paDropzone"
                            :options="dropzoneOptions" 
                            v-on:vdropzone-file-added=""
                            v-on:vdropzone-success="paAfterSuccess"
                            :awss3="Object.assign(paSigningUrl, awss3)"
                            >
                          </vue-dropzone>
                          <small tabindex="-1" class="form-text text-muted" >
                            Es muy importante compartirnos toda la información que tenga al respecto. Los archivos pueden incluir: <br/>
                            Radiografías | Tomografías | Resonancia magnética | Gammagrafía | PET | Biopsia/patología y su reporte | Cistoscopía/Proctoscopía | ECG | Punción lumbar | Química Sanguínea | Exámenes de orina | Recetas Médicas
                          </small>
                        </b-form-group>
                      </div>
                      <!--div class="col-lg-12">
                        <small tabindex="-1" class="form-text text-muted" >
                          Es muy importante compartirnos toda la información que tenga al respecto. <br/>
                          Los archivos pueden incluir: <br/>
                          Radiografías | tomografías | Resonancia magnetica | gammagrafía | PET con contraste | Biopsia/patología y su reporte | Citoscopía/Proctoscopía | ECG | Punción lumbar | Química Sanguínea | Exámenes de orina | Recetas Médicas
                        </small>
                      </div-->
                      <div class="col pt-2">
                        <div class="p-md-4" v-if="attachments.pa.length > 0">
                          <div class="table-responsive bg-white shadow rounded">
                            <table class="table mb-0 table-center">
                              <thead>
                                <tr>
                                  <th scope="col"></th>
                                  <th scope="col">Adjunto</th>
                                  <th scope="col">Tamaño</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item, index) in attachments.pa">
                                  <td><paperclip-icon class="fea icon-sm mr-2"></paperclip-icon></td>
                                  <td><a :href="item.Url" target="_blank"><i class="mdi mdi-open-in-new"> </i> &nbsp;{{item.Filename}} </a></td>
                                  <td><small class="text-dark mx-2 text-muted">{{humanFileSize(item.Size)}}</small></td>
                                  <td>
                                    <a href="#" class="" v-b-modal.DeleteObjectModal @click.prevent="fileNameToDelete = item.Filename; folderToDelete = item.Folder">
                                      <x-circle-icon class="fea icon-sm text-danger"></x-circle-icon>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Cuenta con estudios pendientes por hacer?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="pending_studiesYes" name="pending_studies" class="custom-control-input" value="yes" v-model="form.pending_studies" required/>
                            <label class="custom-control-label" for="pending_studiesYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="pending_studiesNo" name="pending_studies" class="custom-control-input" value="no" v-model="form.pending_studies" required/>
                            <label class="custom-control-label" for="pending_studiesNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.pending_studies === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Cuáles?" >
                          <b-textarea v-model="form.pending_studies_list" :rows="10" :max-rows="15" style="line-height: normal;" required/>
                        </b-form-group>
                      </div>
                    </div>
                  </form>

                  
                  <form class="login-form" ref="form_paadi" v-if="wStepName(wCurrentStep) === 'paadi'">
                    <h4 class="card-title">Patología Actual (en sus propias palabras)</h4>
                    <hr/>
                    <div class="row">
                      <div class="col-md-12 form-inline mb-4">
                        Favor de describir su situación actual en sus propias palabras, así como sus principales inquietudes con respecto a su padecimiento y sus expectativas en cuanto a nuestro servicio
                        <p class="text-muted"><i></i></p>
                      </div>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Qué formato prefiere?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="notes_typeWritten" name="notes_type" class="custom-control-input" value="written" v-model="form.notes_type" required/>
                            <label class="custom-control-label" for="notes_typeWritten">Escrito</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="notes_typeSpoken" name="notes_type" class="custom-control-input" value="spoken" v-model="form.notes_type" required/>
                            <label class="custom-control-label" for="notes_typeSpoken">Hablado (audio)</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.notes_type === 'written'">
                        <b-form-group>
                          <b-textarea v-model="form.notes" :rows="10" :max-rows="15" placeholder="Describa aquí su situación" style="line-height: normal;" required/>
                        </b-form-group>
                      </div>
                      <!--div class="col-12" v-if="form.notes_type === 'spoken1'">
                        <audio-recorder
                          :upload-url="audioRecorderOptions.url"
                          :attempts="audioRecorderOptions.attempts"
                          :time="audioRecorderOptions.time"
                          :headers="audioRecorderOptions.headers"
                          :before-recording="callback"
                          :pause-recording="callback"
                          :after-recording="callback"
                          :select-record="callback"
                          :before-upload="callback"
                          :successful-upload="callback"
                          :failed-upload="callback"/>
                      </div-->

                      <div class="col-12 mt-2" v-if="form.notes_type === 'spoken'">
                        <dictaphone-local @stop="handleRecording($event)"
                        v-slot="{ isRecording, startRecording, stopRecording, deleteRecording }" ref="notesAudioDictaphone">
                          <div class="row justify-content-center">
                            <b-btn class="btn btn-success btn-lg btn-icon btn-pills" @click.prevent="recordingPressed" v-if="!isRecording">
                              <mic-icon class="icons"></mic-icon>
                            </b-btn>
                            <b-btn class="btn btn-danger btn-lg btn-icon btn-pills" @click.prevent="stopRecording" v-if="isRecording">
                              <mic-off-icon class="icons"></mic-off-icon>
                            </b-btn>
                          </div>
                          <div class="row justify-content-center" v-if="isRecording">
                            <img src="/images/sound.gif" class="avatar avatar-md-md" alt=""/>
                          </div>
                          <div class="row mt-3 row justify-content-center" v-if="notesAudio && !isRecording">
                            <audio :src="notesAudio" controls></audio>
                          </div>
                          <div class="row mt-3 justify-content-center" v-if="notesAudioError">
                            <div class="alert alert-danger" role="alert">Ocurrió un error al almacenar el audio :(</div>
                          </div>
                          <div class="row mt-3 row justify-content-center" v-if="!notesAudio">
                            <small tabindex="-1" class="form-text text-muted" >
                              Presione el botón verde para iniciar la grabación, posteriormente presione el botón rojo para finalizar, su navegador le solicitará permiso para activar el audio, acepte.
                            </small>
                          </div>
                        </dictaphone-local>
                      </div>

                      <!--div class="row" v-if="form.notes_type === 'spoken2'">
                        <div class="col-md-6 col-12">
                          <vue-record-audio @result="handleRecording2" :mode="recMode" @stream="onStream" />
                        </div>
                        <div class="col-6">
                          <audio :src="notesAudio" controls v-if="notesAudio"></audio>
                        </div>
                        <vue-dictaphone-spectrum-analyser v-if="isRecording"/>
                      </div-->
                    </div>
                  </form>

                  <form class="login-form" ref="form_appq" v-if="wStepName(wCurrentStep) === 'appq'">
                    <h4 class="card-title">Antecedentes Personales Patológicos y Antecedentes Quirúrgicos</h4>
                    <hr/>
                    <div class="row">
                      <!--Alergias-->
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Padece de alguna alergia?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="alleryYes" name="allergy" class="custom-control-input" value="yes" v-model="form.allergy" required @change="allergyChange"/>
                            <label class="custom-control-label" for="alleryYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="alleryNo" name="allergy" class="custom-control-input" value="no" v-model="form.allergy" required @change="allergyChange"/>
                            <label class="custom-control-label" for="alleryNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <span v-if="form.allergy === 'yes'">
                        <div class="col-md-12 form-inline mb-4">
                          Por favor especifique los elementos/alergenos que le han provocado reacción alérgica, así como la severidad, los síntomas y el año de aparición. Agregue cuantas alergias sean necesarias:
                          <p class="text-muted"><i></i></p>
                        </div>
                        <div class="col-md-12 form-inline mb-4" v-for="(item, index) in form.allergies">
                          <label class="sr-only" :for="'al1_'+index">Elemento/Alergeno</label>
                          <input type="text" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'al1_'+index" placeholder="Elemento/Alergeno" required v-model="item.fields.element"/>
                          <label class="sr-only" :for="'al2_'+index">Severidad</label>
                          <b-select class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'al2_'+index" v-model="item.fields.severity" required>
                            <template #first>
                              <b-select-option :value="null" disabled>-- ¿Severidad? --</b-select-option>
                            </template>

                            <!-- These options will appear after the ones from 'options' prop -->
                            <b-select-option value="Moderada">Moderada</b-select-option>
                            <b-select-option value="Media">Media</b-select-option>
                            <b-select-option value="Grave">Grave</b-select-option>
                          </b-select>
                          <label class="sr-only" :for="'al3_'+index">Año de Inicio</label>
                          <input type="number" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'al3_'+index" placeholder="Año" v-model="item.fields.year" required min="1900" :max="currYear"/>
                          <!--input type="date" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'al3_'+index" placeholder="dd/mm/yyyy" v-model="item.fields.date" required :max="currDate"/-->
                          <label class="sr-only" :for="'al4_'+index">Síntomas</label>
                          <input type="text" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'al4_'+index" placeholder="Síntomas" required v-model="item.fields.symptoms"/>
                          <a href="#" class="btn btn-icon btn-pills btn-outline-danger" v-if="form.allergies.length > 1" @click.prevent="removeFormListItem('allergies', index)"><x-icon class="fea icon-sm"></x-icon></a>
                        </div>
                        <div class="col-md-12 mb-4">
                          <b-btn class="btn btn-primary" @click.prevent="addFormListItem('allergies')">
                            Agregar Alergia
                          </b-btn>
                        </div>
                      </span>
                      <!--Transfusion-->
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Le han realizado una transfusión de sangre?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="transfusionYes" name="transfusion" class="custom-control-input" value="yes" v-model="form.transfusion" required>
                            <label class="custom-control-label" for="transfusionYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="transfusionNo" name="transfusion" class="custom-control-input" value="no" v-model="form.transfusion" required/>
                            <label class="custom-control-label" for="transfusionNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.transfusion === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Cuándo le realizaron la transfusión?" >
                          <div class="position-relative">
                            <!--input type="date" class="form-control" placeholder="dd/mm/yyyy" v-model="form.transfusion_date" required :max="currDate"/-->
                            <vue-monthly-picker v-model="form.transfusion_date" :monthLabels="monthLabels" placeHolder="-- Selecciona Mes y Año --" dateFormat="MMM YYYY" selectedBackgroundColor="#2f55d4" :max="currDate"></vue-monthly-picker>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.transfusion === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Cuál fue la razón de la transfusión?" >
                          <input type="text" class="form-control" placeholder="Razón" required v-model="form.transfusion_reason"/>
                        </b-form-group>
                      </div>
                      <!--Cirugias-->
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Le han realizado una cirugía en el pasado?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="surgeryYes" name="surgery" class="custom-control-input" value="yes" v-model="form.surgery" required @change="surgeryChange"/>
                            <label class="custom-control-label" for="surgeryYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="surgeryNo" name="surgery" class="custom-control-input" value="no" v-model="form.surgery" required @change="surgeryChange"/>
                            <label class="custom-control-label" for="surgeryNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <span v-if="form.surgery === 'yes'">
                        <div class="col-md-12 form-inline mb-4">
                          Por favor especifique las cirugías que ha tenido en el pasado, indicando nombre del procedimiento, año y mes, si  recibió anestesia general, la razón del procedimiento, médico, institución y el resultado de la misma. Agregue cuantas cirugías haya tenido:
                          <p class="text-muted"><i></i></p>
                        </div>
                        <span v-for="(item, index) in form.surgeries">
                          <hr v-if="index > 0"/>
                          <div class="col-md-12 form-inline mb-4" >
                            <label class="sr-only" :for="'su1_'+index">Nombre de cirugía</label>
                            <input type="text" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'su1_'+index" placeholder="Nombre de Cirugía" required v-model="item.fields.name"/>
                            <label class="sr-only" :for="'su2_'+index">Mes y Año</label>
                            <!--input type="date" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'su2_'+index" placeholder="dd/mm/yyyy" v-model="item.fields.date" required :max="currDate"/-->
                            <vue-monthly-picker v-model="item.fields.date" :monthLabels="monthLabels" placeHolder="-- Mes y Año --" dateFormat="MMM YYYY" selectedBackgroundColor="#2f55d4" :max="currDate"></vue-monthly-picker>
                            <label class="sr-only" :for="'su3_'+index">¿Anestesia General?</label>
                            <b-select class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'su3_'+index" v-model="item.fields.general_anesthesia" required>
                              <template #first>
                                <b-select-option :value="null" disabled>-- ¿Anestesia General? --</b-select-option>
                              </template>

                              <!-- These options will appear after the ones from 'options' prop -->
                              <b-select-option value="yes">Sí, recibí anestesia general</b-select-option>
                              <b-select-option value="no">No recibí anestesia general</b-select-option>
                            </b-select>
                          </div>
                          <div class="col-md-12 form-inline mb-4">
                            <label class="sr-only" :for="'su4_'+index">Razón</label>
                            <input type="text" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'su4_'+index" placeholder="Razón" required v-model="item.fields.reason"/>
                            <label class="sr-only" :for="'su5_'+index">Médico</label>
                            <input type="text" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'su5_'+index" placeholder="Médico" required v-model="item.fields.doctor"/>
                            <label class="sr-only" :for="'su6_'+index">Institución</label>
                            <input type="text" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'su6_'+index" placeholder="Institución" required v-model="item.fields.institution"/>
                            <a href="#" class="btn btn-icon btn-pills btn-outline-danger" v-if="form.surgeries.length > 1" @click.prevent="removeFormListItem('surgeries', index)"><x-icon class="fea icon-sm"></x-icon></a>
                          </div>
                        </span>
                        <div class="col-md-12 mb-4">
                          <b-btn class="btn btn-primary" @click.prevent="addFormListItem('surgeries')">
                            Agregar Cirugía
                          </b-btn>
                        </div>
                      </span>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Alguna condición o característica de su cuerpo (físico) que sea importante mencionar y tomar en cuenta?" >
                          <input type="text" class="form-control" placeholder="Mencionar condición" required v-model="form.body"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Padece o ha padecido alguna otra enfermedad?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="diseaseYes" name="disease" class="custom-control-input" value="yes" v-model="form.disease" required @change="diseaseChange"/>
                            <label class="custom-control-label" for="diseaseYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="diseaseNo" name="disease" class="custom-control-input" value="no" v-model="form.disease" required @change="diseaseChange"/>
                            <label class="custom-control-label" for="diseaseNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <!--span v-if="form.surgery === 'yes'">
                        <div class="col-md-12 form-inline mb-4">
                          Por favor especifica las cirugias que has tenido en el pasado, indicando nombre del procedimiento, fecha, si recibiste anestesia general, la razón del procedimiento, médico, insititución y el resultado de la misma, agrega cuantas cirugias hayas tenido:
                          <p class="text-muted"><i></i></p>
                        </div>
                        <span v-for="(item, index) in form.surgeries">
                          <div class="col-lg-12 mt-4 pt-2">
                            <div class="card shadow rounded border-0 overflow-hidden">
                              <div class="row no-gutters">
                                <div class="card-body">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <b-form-group label-cols-sm="6" label-cols-lg="6" content-cols-sm="6" content-cols-lg="6" label="Nombre del procedimiento:" >
                                        <input type="text" class="form-control" placeholder="ej. Cirugía" required v-model="item.fields.name" size="sm"/>
                                      </b-form-group>
                                    </div>
                                    <div class="col-md-6">
                                      <b-form-group label-cols-sm="6" label-cols-lg="6" content-cols-sm="6" content-cols-lg="6" label="Fecha de la cirugía:" >
                                        <input type="date" class="form-control" placeholder="dd/mm/yyyy" v-model="item.fields.date" size="sm" required :max="currDate"/>
                                      </b-form-group>
                                    </div>
                                    <div class="col-md-6">
                                      <b-form-group label-cols-sm="6" label-cols-lg="6" content-cols-sm="6" content-cols-lg="6" label="Anestesía general:" >
                                        <b-select class="form-control" v-model="item.fields.general_anesthesia" required size="sm">
                                          <template #first>
                                            <b-select-option :value="null" disabled>-- ¿Anestesia General? --</b-select-option>
                                          </template>
                                          <b-select-option value="yes">Sí</b-select-option>
                                          <b-select-option value="no">No</b-select-option>
                                        </b-select>
                                      </b-form-group>
                                    </div>
                                    <div class="col-md-6">
                                      <b-form-group label-cols-sm="6" label-cols-lg="6" content-cols-sm="6" content-cols-lg="6" label="Razón:" >
                                        <input type="text" class="form-control" placeholder="ej. de Razones" required v-model="item.fields.reason" size="sm"/>
                                      </b-form-group>
                                    </div>
                                    <div class="col-md-6">
                                      <b-form-group label-cols-sm="6" label-cols-lg="6" content-cols-sm="6" content-cols-lg="6" label="Médico:" >
                                        <input type="text" class="form-control" placeholder="Nombre del Médico" required v-model="item.fields.doctor" size="sm"/>
                                      </b-form-group>
                                    </div>
                                    <div class="col-md-6">
                                      <b-form-group label-cols-sm="6" label-cols-lg="6" content-cols-sm="6" content-cols-lg="6" label="Institución:" >
                                        <input type="text" class="form-control" placeholder="Nombre de la Insitución" required v-model="item.fields.institution" size="sm"/>
                                      </b-form-group>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12">
                                      <b-btn class="btn btn-danger float-right" @click.prevent="removeFormListItem('surgeries', index)" v-if="form.surgeries.length > 1">
                                        Eliminar {{item.fields.name}}
                                      </b-btn>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                        <div class="col-md-12 mb-4 mt-4">
                          <b-btn class="btn btn-primary" @click.prevent="addFormListItem('surgeries')">
                            Agregar Cirugía
                          </b-btn>
                        </div>
                      </span-->
                      <!--Enfermedades-->
                      

                      <!--div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Padece o ha padecido alguna otra enfermedad?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="diseaseYes" name="disease" class="custom-control-input" value="yes" v-model="form.disease" required @change="diseaseChange"/>
                            <label class="custom-control-label" for="diseaseYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="diseaseNo" name="disease" class="custom-control-input" value="no" v-model="form.disease" required @change="diseaseChange"/>
                            <label class="custom-control-label" for="diseaseNo">No</label>
                          </div>
                        </b-form-group>
                      </div-->
                      <span v-if="form.disease === 'yes'">
                        <div class="col-md-12 form-inline mb-4">
                          Por favor especifique las enfermedades que padece o ha padecido en el pasado. Indique el mes y año de diagnóstico, si considera que ya fue resuelta y cualquier información adicional que considere relevante:
                          <p class="text-muted"><i></i></p>
                        </div>
                        <span v-for="(item, index) in form.diseases">
                          <hr v-if="index > 0"/>
                          <div class="col-md-12 form-inline mb-4" >
                            <label class="sr-only" :for="'di1_'+index">Enfermedad</label>
                            <b-select class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'di1_'+index" v-model="item.fields.name" required>
                              <template #first>
                                <b-select-option :value="null" disabled>-- Enfermedad --</b-select-option>
                              </template>

                              <!-- These options will appear after the ones from 'options' prop -->
                              <b-select-option value="Diabetes Mellitus I">Diabetes Mellitus I</b-select-option>
                              <b-select-option value="Diabetes Mellitus II">Diabetes Mellitus II</b-select-option>
                              <b-select-option value="Diabetes Mellitus Gestacional">Diabetes Mellitus Gestacional</b-select-option>
                              <b-select-option value="Hipertensión (presión alta)">Hipertensión (presión alta)</b-select-option>
                              <b-select-option value="Insuficiencia Renal">Insuficiencia Renal</b-select-option>
                              <b-select-option value="Insuficiencia Hepática">Insuficiencia Hepática</b-select-option>
                              <b-select-option value="other">Otra</b-select-option>
                            </b-select>

                            <label class="sr-only" :for="'di2_'+index" v-if="item.fields.name === 'other'">¿Cuál?</label>
                            <input type="text" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'di2_'+index" placeholder="¿Cuál?" required v-model="item.fields.name_other" v-if="item.fields.name === 'other'"/>

                            <label class="sr-only" :for="'di4_'+index">Mes y año de diagnóstico?</label>
                            <!--input type="number" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'di4_'+index" placeholder="Año" v-model="item.fields.year" required min="1900" :max="currYear"/-->
                            <vue-monthly-picker :id="'di4_'+index" v-model="item.fields.date" :monthLabels="monthLabels" placeHolder="-- Mes y Año --" dateFormat="MMM YYYY" selectedBackgroundColor="#2f55d4" :max="currDate"></vue-monthly-picker>
                          </div>
                          <div class="col-md-12 form-inline mb-4">
                            <label class="sr-only" :for="'di3_'+index">¿Puede considerarse resuelta?</label>
                            <b-select class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'di3_'+index" v-model="item.fields.resolved" required>
                              <template #first>
                                <b-select-option :value="null" disabled>-- ¿Puede considerarse resuelta? --</b-select-option>
                              </template>
                              <b-select-option value="yes">Sí, la considero resuelta</b-select-option>
                              <b-select-option value="no">No, aún no está resuelta</b-select-option>
                            </b-select>
                          </div>
                          <div class="col-md-12 mb-4">
                            <label class="sr-only" :for="'di5_'+index">Información adicional acerca de la enfermedad</label>
                            <!--input type="text" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'di5_'+index" placeholder="Información Adicional sobre esta enfermedad" required v-model="item.fields.notes"/-->
                            <div class="form-group">
                              <b-textarea v-model="item.fields.notes" :rows="5" :max-rows="10" style="line-height: normal;" placeholder="Información adicional acerca de la enfermedad" required/>
                            </div>
                            <a href="#" class="btn btn-icon btn-pills btn-outline-danger" v-if="form.diseases.length > 1" @click.prevent="removeFormListItem('diseases', index)"><x-icon class="fea icon-sm"></x-icon></a>
                          </div>
                        </span>
                        <div class="col-md-12 mb-4">
                          <b-btn class="btn btn-primary" @click.prevent="addFormListItem('diseases')">
                            Agregar Enfermedad
                          </b-btn>
                        </div>
                      </span>
                      <div class="col-md-12" v-if="form.disease === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="Por favor cargue TODOS los archivos relacionados a dichas enfermedades:" description="">
                          <vue-dropzone 
                            ref="othersDropzone" 
                            id="othersDropzone"
                            :options="dropzoneOptions" 
                            v-on:vdropzone-file-added=""
                            v-on:vdropzone-success="othersAfterSuccess"
                            :awss3="Object.assign(othersSigningUrl, awss3)"
                            >
                          </vue-dropzone>
                          <small tabindex="-1" class="form-text text-muted" >
                            Es muy importante compartirnos toda la información que tenga al respecto. 
                            Los archivos pueden incluir: <br/>
                            Notas de evolución, exámenes de laboratorio y/o imagen, etc...
                          </small>
                        </b-form-group>
                      </div>
                      <div class="col pt-2">
                        <div class="p-md-4" v-if="attachments.others.length > 0 && form.disease === 'yes'">
                          <div class="table-responsive bg-white shadow rounded">
                            <table class="table mb-0 table-center">
                              <thead>
                                <tr>
                                  <th scope="col"></th>
                                  <th scope="col">Adjunto</th>
                                  <th scope="col">Tamaño</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item, index) in attachments.others">
                                  <td><paperclip-icon class="fea icon-sm mr-2"></paperclip-icon></td>
                                  <td><a :href="item.Url" target="_blank"><i class="mdi mdi-open-in-new"> </i> &nbsp;{{item.Filename}} </a></td>
                                  <td><small class="text-dark mx-2 text-muted">{{humanFileSize(item.Size)}}</small></td>
                                  <td>
                                    <a href="#" class="" v-b-modal.DeleteObjectModal @click.prevent="fileNameToDelete = item.Filename; folderToDelete = item.Folder">
                                      <x-circle-icon class="fea icon-sm text-danger"></x-circle-icon>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                    </div>
                  </form>

                  <form class="login-form" ref="form_agine" v-if="wStepName(wCurrentStep) === 'agine'">
                    <h4 class="card-title">Antecedente Ginecobstétrico</h4>
                    <hr/>
                    <div class="row">
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="4" label="Edad de primera menstruación:" >
                          <!--input type="date" class="form-control" placeholder="dd/mm/yyyy" v-model="form.f_menstruation_age" :max="currDate"/-->
                          <!--input type="number" class="form-control" placeholder="Edad en años" v-model="form.f_menstruation_age" min="0" max="100"/>
                          <small tabindex="-1" class="form-text text-muted">
                            Dejar en blanco en caso de no haber tenido aún su primera menstruación
                          </small-->
                          <b-select class="form-control mb-2 mr-sm-2 mb-sm-0" v-model="form.f_menstruation_age" required>
                            <template #first>
                              <b-select-option :value="null" disabled>-- Selecciona Edad --</b-select-option>
                            </template>

                            <!-- These options will appear after the ones from 'options' prop -->
                            <b-select-option value="na">No ha ocurrido</b-select-option>
                            <b-select-option value="0-8">8 o menos</b-select-option>
                            <b-select-option value="9-11">9-11</b-select-option>
                            <b-select-option value="12-15">12-15</b-select-option>
                            <b-select-option value="16+">16+</b-select-option>

                          </b-select>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.f_menstruation_age && form.f_menstruation_age !== 'na'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="4" label="Inicio de Vida Sexual:" >
                          <!--input type="number" class="form-control" placeholder="dd/mm/yyyy" v-model="form.ivsa_age" required :max="currDate"-->
                          <!--input type="number" class="form-control" placeholder="Edad en años" v-model="form.ivsa_age" min="0" max="100"/>
                          <small tabindex="-1" class="form-text text-muted">
                            Dejar en blanco en caso de no haber iniciado aún su vida sexual
                          </small-->
                          <b-select class="form-control mb-2 mr-sm-2 mb-sm-0" v-model="form.ivsa_age" required>
                            <template #first>
                              <b-select-option :value="null" disabled>-- Selecciona Edad --</b-select-option>
                            </template>

                            <!-- These options will appear after the ones from 'options' prop -->
                            <b-select-option value="na">No ha ocurrido</b-select-option>
                            <b-select-option value="0-18">18 o menos</b-select-option>
                            <b-select-option value="19-20">19-20</b-select-option>
                            <b-select-option value="21+">21+</b-select-option>

                          </b-select>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.f_menstruation_age && form.f_menstruation_age !== 'na'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="4" label="Mes y año de última menstruación" >
                          <!--input type="date" class="form-control" placeholder="dd/mm/yyyy" v-model="form.l_menstruation_age" :max="currDate"/-->
                          <!--input type="number" class="form-control" placeholder="Edad en años" v-model="form.l_menstruation_age" :min="form.f_menstruation_age" max="100"/-->
                          <vue-monthly-picker v-model="form.l_menstruation_age" :monthLabels="monthLabels" placeHolder="-- Selecciona Mes y Año --" dateFormat="MMM YYYY" selectedBackgroundColor="#2f55d4" :max="currDate"></vue-monthly-picker>
                          <!--small tabindex="-1" class="form-text text-muted">
                            Dejar en blanco en caso de continuar menstruando
                          </small-->
                        </b-form-group>
                      </div>

                      <div class="col-md-12" v-if="form.ivsa_age && form.ivsa_age !== 'na'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Está embarazada?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="pregnantYes" name="pregnant" class="custom-control-input" value="yes" v-model="form.pregnant" required>
                            <label class="custom-control-label" for="pregnantYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="pregnantNo" name="pregnant" class="custom-control-input" value="no" v-model="form.pregnant" required/>
                            <label class="custom-control-label" for="pregnantNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.ivsa_age && form.ivsa_age !== 'na'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Ha tenido embarazos previos?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="previous_pregnanciesYes" name="previous_pregnancies" class="custom-control-input" value="yes" v-model="form.previous_pregnancies" required>
                            <label class="custom-control-label" for="previous_pregnanciesYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="previous_pregnanciesNo" name="previous_pregnancies" class="custom-control-input" value="no" v-model="form.previous_pregnancies" required/>
                            <label class="custom-control-label" for="previous_pregnanciesNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.previous_pregnancies === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="3" label="¿Cuántos embarazos previos ha tenido?" >
                          <input type="number" class="form-control" placeholder="Cuántos Embarazos" v-model="form.previous_pregnancies_qty" required min="1" max="30"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.previous_pregnancies === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="3" label="¿Cuántos partos ha tenido?" >
                          <input type="number" class="form-control" placeholder="Cuántos Partos" v-model="form.birth_qty" required min="0" :max="form.previous_pregnancies_qty"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.previous_pregnancies === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="3" label="¿Cuántas cesáreas ha tenido?" >
                          <input type="number" class="form-control" placeholder="Cuántas Cesáreas" v-model="form.c_section_qty" required min="0" :max="form.previous_pregnancies_qty"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.previous_pregnancies === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="3" label="¿Cuántos abortos ha tenido?" >
                          <input type="number" class="form-control" placeholder="Cuántos Abortos" v-model="form.abortion_qty" required min="0" :max="form.previous_pregnancies_qty - form.birth_qty"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.previous_pregnancies === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="3" label="¿Cuántos meses ha lactado en total?" >
                          <input type="number" class="form-control" placeholder="Total Meses" v-model="form.lactation_months" required min="1" max="600"/>
                          <small tabindex="-1" class="form-text text-muted">
                            Contando el total de meses de lactancia en su vida
                          </small>
                        </b-form-group>
                      </div>
                    </div>
                  </form>

                  <!-- Antecedentes Personales No Patológicos -->
                  <form class="login-form" ref="form_apnp" v-if="wStepName(wCurrentStep) === 'apnp'">
                    <h4 class="card-title">Antecedentes Personales No Patológicos</h4>
                    <hr/>
                    <div class="row">
                      <!--Alergias-->
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Fuma / Fumó?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="smokeYes" name="smoke" class="custom-control-input" value="yes" v-model="form.smoke" required/>
                            <label class="custom-control-label" for="smokeYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="smokeNo" name="smoke" class="custom-control-input" value="no" v-model="form.smoke" required/>
                            <label class="custom-control-label" for="smokeNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.smoke === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Cuántos años fumó / ha fumado?" >
                          <input type="number" class="form-control" placeholder="Cantidad de Años" v-model="form.smoke_years" required min="1" max="100"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.smoke === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Cuántos cigarros al día?" >
                          <input type="number" class="form-control" placeholder="Cantidad de Cigarros" v-model="form.smoke_qty_day" required min="1" max="500"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Bebe alcohol?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="alcoholYes" name="alcohol" class="custom-control-input" value="yes" v-model="form.alcohol" required/>
                            <label class="custom-control-label" for="alcoholYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="alcoholNo" name="alcohol" class="custom-control-input" value="no" v-model="form.alcohol" required/>
                            <label class="custom-control-label" for="alcoholNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.alcohol === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Con qué frecuencia?" >
                          <b-select class="form-control" v-model="form.alcohol_frequency" required>
                              <template #first>
                                <b-select-option :value="null" disabled>-- Frecuencia --</b-select-option>
                              </template>

                              <!-- These options will appear after the ones from 'options' prop -->
                              <b-select-option value="Diario">Diario</b-select-option>
                              <b-select-option value="Fines de Semana">Fines de Semana</b-select-option>
                              <b-select-option value="Social">Social</b-select-option>
                            </b-select>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.alcohol === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Qué cantidad (copas) en esa frecuencia?" >
                          <input type="number" class="form-control" placeholder="Cantidad de Copas" v-model="form.alcohol_amount" required min="1" max="100"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.alcohol === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Cuántos años ha bebido así?" >
                          <input type="number" class="form-control" placeholder="Cantidad de Años" v-model="form.alcohol_years" required min="1" max="100"/>
                        </b-form-group>
                      </div>
                      
                      <!-- Drugs-->
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Consume o ha consumido drogas?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="drugsYes" name="drugs" class="custom-control-input" value="yes" v-model="form.drugs" required/>
                            <label class="custom-control-label" for="drugsYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="drugsNo" name="drugs" class="custom-control-input" value="no" v-model="form.drugs" required/>
                            <label class="custom-control-label" for="drugsNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.drugs === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Cuál/cuáles?" >
                          <input type="text" class="form-control" placeholder="ej. Cannabis, Heroína, etc..." required v-model="form.drugs_description"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.drugs === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Con qué frecuencia?" >
                          <b-select class="form-control" v-model="form.drugs_frequency" required>
                              <template #first>
                                <b-select-option :value="null" disabled>-- Frecuencia --</b-select-option>
                              </template>

                              <!-- These options will appear after the ones from 'options' prop -->
                              <b-select-option value="Diario">Diario</b-select-option>
                              <b-select-option value="3 veces por semana">3 veces por semana</b-select-option>
                              <b-select-option value="Social">Esporádicamente</b-select-option>
                            </b-select>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.drugs === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Cuántos años consumió / ha consumido?" >
                          <input type="number" class="form-control" placeholder="ej. 10" v-model="form.drugs_years" required min="1" max="100"/>
                        </b-form-group>
                      </div>

                      <!--Sleep-->
                      <!--div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Considera que sus horas de sueño son buenas?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="sleepYes" name="sleep" class="custom-control-input" value="yes" v-model="form.sleep" required/>
                            <label class="custom-control-label" for="sleepYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="sleepNo" name="sleep" class="custom-control-input" value="no" v-model="form.sleep" required/>
                            <label class="custom-control-label" for="sleepNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Por qué?" >
                          <input type="text" class="form-control" placeholder="" required v-model="form.sleep_description"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Cuántas horas promedio de sueño cada 24 horas?" >
                          <input type="number" class="form-control" placeholder="ej. 8" v-model="form.sleep_hours" required min="1" max="24"/>
                        </b-form-group>
                      </div-->
                        <!--Excercise-->
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Realiza ejercicio con frecuencia?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="exerciseYes" name="exercise" class="custom-control-input" value="yes" v-model="form.exercise" required/>
                            <label class="custom-control-label" for="exerciseYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="exerciseNo" name="exercise" class="custom-control-input" value="no" v-model="form.exercise" required/>
                            <label class="custom-control-label" for="exerciseNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <!--div class="col-md-12" v-if="form.exercise === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Con qué frecuencia?" >
                          <b-select class="form-control" v-model="form.exercise_frequency" required>
                              <template #first>
                                <b-select-option :value="null" disabled>-- Frecuencia --</b-select-option>
                              </template>

                              <b-select-option value="Diario">Diario</b-select-option>
                              <b-select-option value="Fines de Semana">Fines de Semana</b-select-option>
                              <b-select-option value="Social">Social</b-select-option>
                            </b-select>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.exercise === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Cuántos minutos por sesión?" >
                          <input type="number" class="form-control" placeholder="ej. 30" v-model="form.excercise_minutes" required min="1" max="600"/>
                        </b-form-group>
                      </div-->  

                      <!-- Eating -->
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Qué describe mejor su dieta típica?" >
                          <b-select class="form-control" v-model="form.eating_habit" required>
                            <template #first>
                              <b-select-option :value="null" disabled>-- Tipo de Dieta --</b-select-option>
                            </template>

                            <b-select-option value="Normal">Normal</b-select-option>
                            <b-select-option value="Vegetariana">Vegetariana</b-select-option>
                            <b-select-option value="Hiposódica">Hiposódica (Baja en Sodio)</b-select-option>
                            <b-select-option value="Hipograsa">Hipograsa (Baja en Grasa)</b-select-option>
                            <b-select-option value="Para Diabéticos">Para Diabéticos</b-select-option>
                          </b-select>
                        </b-form-group>
                      </div>

                      <!-- Direct Relatives -->
                      <div class="col-md-12">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Tiene familiares que hayan padecido cáncer, algún tumor o alguna enfermedad catastrófica?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="direct_relativeYes" name="direct_relative" class="custom-control-input" value="yes" v-model="form.direct_relative" required @change="directRelativeChange"/>
                            <label class="custom-control-label" for="direct_relativeYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="direct_relativeNo" name="direct_relative" class="custom-control-input" value="no" v-model="form.direct_relative" required @change="directRelativeChange"/>
                            <label class="custom-control-label" for="direct_relativeNo">No</label>
                          </div>
                        </b-form-group>
                      </div>

                      <span v-if="form.direct_relative === 'yes'">
                        <div class="col-md-12 form-inline mb-4">
                          Por favor especifique el familiar, su enfermedad, si aún vive y a qué edad aproximada fue diagnosticado, agregue cuantos sean necesarios:
                          <p class="text-muted"><i></i></p>
                        </div>
                        <span v-for="(item, index) in form.direct_relatives">
                          <!--hr v-if="index > 0"/-->
                          <div class="col-md-12 form-inline mb-4" >
                            <label class="sr-only" :for="'dr1_'+index">¿Quién?</label>
                            <b-select class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'dr1_'+index" v-model="item.fields.relative" required>
                              <template #first>
                                <b-select-option :value="null" disabled>-- ¿Quién? --</b-select-option>
                              </template>

                              <!-- These options will appear after the ones from 'options' prop -->
                              <b-select-option value="father">Padre</b-select-option>
                              <b-select-option value="mother">Madre</b-select-option>
                              <b-select-option value="brother">Hermano</b-select-option>
                              <b-select-option value="sister">Hermana</b-select-option>

                              <b-select-option value="uncle">Tío(a)</b-select-option>
                              <b-select-option value="cousin">Primo(a)</b-select-option>
                              <b-select-option value="grandparent">Abuelo(a)</b-select-option>
                            </b-select>

                            <label class="sr-only" :for="'dr2_'+index">Nombre de la enfermedad/padecimiento</label>
                            <input type="text" class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'dr2_'+index" placeholder="Padecimiento" required v-model="item.fields.disease"/>

                            <label class="sr-only" :for="'dr3_'+index">¿Está vivo?</label>
                            <b-select class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'dr3_'+index" v-model="item.fields.alive" required>
                              <template #first>
                                <b-select-option :value="null" disabled>-- ¿Está vivo? --</b-select-option>
                              </template>

                              <!-- These options will appear after the ones from 'options' prop -->
                              <b-select-option value="yes">Sí, está vivo</b-select-option>
                              <b-select-option value="no">No, ya falleció</b-select-option>
                            </b-select>

                            <label class="sr-only" :for="'dr4_'+index">¿Edad de Diagnóstico?</label>
                            <b-select class="form-control mb-2 mr-sm-2 mb-sm-0" :id="'dr4_'+index" v-model="item.fields.diagnosis_age" required>
                              <template #first>
                                <b-select-option :value="null" disabled>-- ¿Edad de Diagnóstico? --</b-select-option>
                              </template>

                              <!-- These options will appear after the ones from 'options' prop -->
                              <b-select-option value="0-9">0-9</b-select-option>
                              <b-select-option value="10-19">10-19</b-select-option>
                              <b-select-option value="20-29">20-29</b-select-option>
                              <b-select-option value="30-39">30-39</b-select-option>
                              <b-select-option value="40-49">40-49</b-select-option>
                              <b-select-option value="50-59">50-59</b-select-option>
                              <b-select-option value="60-69">60-69</b-select-option>
                              <b-select-option value="70+">70+</b-select-option>

                            </b-select>
                            
                            <a href="#" class="btn btn-icon btn-pills btn-outline-danger" v-if="form.direct_relatives.length > 1" @click.prevent="removeFormListItem('direct_relatives', index)"><x-icon class="fea icon-sm"></x-icon></a>
                          </div>
                        </span>
                        <div class="col-md-12 mb-4">
                          <b-btn class="btn btn-primary" @click.prevent="addFormListItem('direct_relatives')">
                            Agregar Familiar
                          </b-btn>
                        </div>
                      </span>
                      <!-- Indirect Relatives -->
                      <!--div class="col-md-12">
                        <hr/>
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Tiene familiares indirectos (tíos, abuelos, primos) que hayan padecido cáncer, algún tumor o alguna enfermedad catastrófica?" >
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="indirect_relativeYes" name="indirect_relative" class="custom-control-input" value="yes" v-model="form.indirect_relative" required/>
                            <label class="custom-control-label" for="indirect_relativeYes">Sí</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="indirect_relativeNo" name="indirect_relative" class="custom-control-input" value="no" v-model="form.indirect_relative" required/>
                            <label class="custom-control-label" for="indirect_relativeNo">No</label>
                          </div>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.indirect_relative === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Quiénes?">
                          <input type="text" class="form-control" placeholder="ej. Tío, Abuelo, Primo, etc..." required v-model="form.indirect_relative_who"/>
                        </b-form-group>
                      </div>
                      <div class="col-md-12" v-if="form.indirect_relative === 'yes'">
                        <b-form-group label-cols-sm="6" label-cols-lg="4" content-cols-sm="6" content-cols-lg="7" label="¿Qué enfermedades tuvieron?" >
                          <input type="text" class="form-control" placeholder="Ej. Cáncer, Tumores, Cardiopatías, etc..." required v-model="form.indirect_relative_disease"/>
                        </b-form-group>
                      </div-->

                    </div>
                  </form>

                  <form class="login-form" ref="form_checkout" v-if="wStepName(wCurrentStep) === 'checkout'">
                    <div class="row justify-content-center">
                      <div class="col-md-8">
                        <span>
                          <div class="d-flex mb-4 justify-content-between">
                              <h5>Checkout</h5>
                              <!--router-link to="/shop-cart" class="text-muted h6">Más Información</router-link-->
                          </div>
                          <div class="table-responsive">
                            <table class="table table-center table-padding mb-0">
                                <tbody>
                                    <!--tr>
                                        <td class="h6 border-0">Servicio</td>
                                        <td class="text-center font-weight-bold border-0">$ 1,850 mxn</td>
                                    </tr-->
                                    <!--tr>
                                        <td class="h6">Impuestos</td>
                                        <td class="text-center font-weight-bold">$ 0.00 mxn</td>
                                    </tr-->
                                    <tr class="bg-light">
                                        <td class="h5 font-weight-bold">Subtotal servicio</td>
                                        <td class="text-center text-primary h4 font-weight-bold">$ 1,850 mxn</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="text-muted">
                              <i>
                                * No incluye impuestos aplicables
                              </i>
                            </p>

                            <div class="mt-4 pt-2">
                              <stripe-checkout
                                ref="checkoutRef"
                                :pk="stripePublishableKey"
                                :session-id="stripeSessionId"
                                @loading="v => stripeLoading = v"
                              />
                            </div>
                          </div>
                        </span>
                      </div>
                      <div class="col-md-10">
                          <p class="" style="text-align: justify; text-justify: inter-word !important;">
                            
                              Al realizar tu pago darás inicio al servicio de segunda opinión médica Duppla. <br/>El cargo es único y cubre la totalidad del servicio.<br/>
                              Si tienes duda acerca de los pasos que siguen, <strong>escríbenos vía WhatsApp para poder ayudarte.</strong>
                            
                          </p>
                      </div>
                      <div class="col-md-10" v-if="user.fields.stripe_payment_method_type === 'oxxo'">
                        <div class="alert alert-warning mt-4" role="alert">
                           <span class="alert-content"> Has iniciado un proceso de pago en efectivo con tiendas Oxxo, si ya has relaizado tu pago recuerda que puede tardar hasta un día hábil posterior al día de pago para que se vea reflejado.
                            <p/><p/>
                         </span>
                        </div>
                        <a target="_blank" :href="user.fields?user.fields.stripe_hosted_voucher_url:''">
                          Da click aquí para reimprimir tu ficha de pago (abrirá en otra pestaña).
                        </a>
                      </div>
                    </div>
                  </form>
                  <form class="login-form" ref="form_checkout_confirmation" v-if="wStepName(wCurrentStep) === 'checkout_confirmation'">
                    <!--div class="row justify-content-center">
                      <div class="col-md-8">
                        <div class="alert alert-success" role="alert">Gracias por tu pago!</div>
                        Tu pago con la tarjeta
                        <br/>
                        <br/>
                        <strong>
                          {{user.fields?user.fields.card_brand:''}}  **** **** **** {{user.fields?user.fields.card_last4:''}} 
                        </strong>
                        <br/>
                        <br/>
                        ha sido acreditado satisfactoriamente, 
                        <a target="_blank" :href="user.fields?user.fields.receipt_url:''">
                          da click aqui para obtener tu recibo de pago (abrirá en otra pestaña).
                        </a>
                      </div>
                    </div-->
                    <div class="row justify-content-center">
                      <div class="col-12">
                        <div class="text-center">
                          <div
                            class="icon d-flex align-items-center justify-content-center bg-soft-primary rounded-circle mx-auto"
                            style="height: 90px; width: 90px"
                          >
                            <i class="uil uil-thumbs-up align-middle h1 mb-0"></i>
                          </div>
                          <h1 class="my-4 font-weight-bold">¡Gracias por tu pago!</h1>
                          <p class="para-desc mx-auto">
                            Tu pago ha sido acreditado satisfactoriamente, da click en siguiente para continuar con tu proceso.
                            <br/><br/>
                            <a target="_blank" :href="user.fields?user.fields.receipt_url:''">
                              Da click aquí para obtener tu recibo de pago (abrirá en otra pestaña).
                            </a>
                          </p>
                          <p class="para-desc mx-auto text-muted">
                            <i>Cualquier duda relacionada a tu pago no dudes en contactarnos.</i>
                          </p>
                        </div>
                      </div>
                      <!--end col-->
                    </div>
                  </form>
                  <form class="login-form" ref="form_end" v-if="wStepName(wCurrentStep) === 'end'">
                    <div class="row justify-content-center">
                      <div class="col-12">
                        Has completado exitosamente tu expediente.<br/><br/>
                        ¿Qué Sigue?<br/><br/>
                        Iniciaremos el proceso de segunda opinión con nuestro grupo de médicos expertos, mantendremos comunicación contigo vía Email y Whatsapp.
                        <br/>
                        <br/><br/>
                        Cualquier duda que tengas puedes comunicarte con nosotros a <a href="mailto:hola@duppla.doctor">hola@duppla.doctor</a>, o bien, mediante nuestro enlace a Whatsapp.
                      </div>
                    </div>
                  </form>
                <hr/>
                <b-row align-h="between" class="row mt-5" v-if="!loadingMedicalRecord">
                  <b-col class="text-left col-5 col-md-4 col-lg-3">
                    <b-btn class="btn btn-primary btn-block d-none d-md-block" @click.prevent="previousStep" :disabled="savingForm" v-if="!wFirstStep">
                      <arrow-left-icon class="fea icon-sm"></arrow-left-icon> Anterior
                    </b-btn>
                    <b-btn class="btn btn-primary btn-icon btn-pills d-md-none btn-lg" @click.prevent="previousStep" :disabled="savingForm" v-if="!wFirstStep">
                      <arrow-left-icon class="icons"></arrow-left-icon>
                    </b-btn>
                  </b-col>
                  <b-col class="text-right col-5 col-md-4 col-lg-3" v-if="!wLastStep && wStepName(wCurrentStep) != 'checkout'">
                    <b-btn class="btn btn-primary btn-block d-none d-md-block" @click.prevent="nextStep" :disabled="savingForm">
                      Siguiente <arrow-right-icon class="fea icon-sm"></arrow-right-icon>
                    </b-btn>
                    <b-btn class="btn btn-primary btn-icon btn-pills d-md-none btn-lg" @click.prevent="nextStep" :disabled="savingForm">
                      <arrow-right-icon class="icons"></arrow-right-icon>
                    </b-btn>
                  </b-col>
                  <b-col class="text-right col-7 col-md-4 col-lg-3" v-if="wLastStep">
                    <router-link to="/private/dashboard" class="btn btn-success btn-block" :disabled="savingForm">
                      Finalizar
                    </router-link>
                  </b-col>
                  <b-col class="text-right col-7 col-md-5 col-lg-4" 
                  v-if="wStepName(wCurrentStep) === 'checkout' && user.fields.stripe_payment_method_type !== 'oxxo' && user.fields.stripe_session_status !== 'complete'">
                    <b-btn class="btn btn-primary btn-block" @click.prevent="invokePayment" :disabled="savingForm || paying">
                      {{paying?'Enviando a Pago ...':'Continuar a Pago'}}
                    </b-btn>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
        <!--end row-->
        <b-modal ref="DeleteObjectModal" id="DeleteObjectModal" title="Eliminar Adjunto" centered>
          <div class="bg-white p-3 rounded box-shadow">
            <p class="text-muted mb-0">
              ¿Desea eliminar definitivamente el archivo "{{fileNameToDelete}}"?<br/>
              <i class="text-muted">Esta acción no se puede revertir</i>
            </p>
          </div>
          <template #modal-footer="{ ok, cancel, hide }">
            <b-btn @click="cancel()" class="btn btn-secondary" :disabled="deletingObject">
              Cancelar
            </b-btn>
            <b-btn @click="deleteObject(folderToDelete, fileNameToDelete)" class="btn btn-primary" :disabled="deletingObject">
              {{ (deletingObject?'Eliminando...':'Eliminar') }}
            </b-btn>
          </template>
          <!--div slot="modal-footer" class="w-100">
            <b-btn @click="deleteObject(fileNameToDelete)" class="btn btn-primary">
              Eliminar
            </b-btn>
            <b-btn class="btn btn-secondary">
              Cancelar
            </b-btn>
          </div-->
        </b-modal>
        <b-modal ref="ReplaceAudioModal" id="ReplaceAudioModal" title="Reemplazar Audio" centered>
          <div class="bg-white p-3 rounded box-shadow">
            <p class="text-muted mb-0">
              Ya existe un audio almacenado, al continuar iniciará una nueva grabación que reemplazará el audio actual <br/><br/>¿Desea descartar el audio actual y continuar?<br/>
            </p>
          </div>
          <template #modal-footer="{ ok, cancel, hide }">
            <b-btn @click="cancel()" class="btn btn-secondary">
              Cancelar
            </b-btn>
            <b-btn @click="startRecordingAudio" class="btn btn-primary">
              Continuar
            </b-btn>
          </template>
          <!--div slot="modal-footer" class="w-100">
            <b-btn @click="deleteObject(fileNameToDelete)" class="btn btn-primary">
              Eliminar
            </b-btn>
            <b-btn class="btn btn-secondary">
              Cancelar
            </b-btn>
          </div-->
        </b-modal>
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!--Switcher /-->
    <whatsapp-widget source="Medical Record Wizard Widget"/>
  </div>
</template>
  